.breadcrumbs { margin: 0 0 6px; padding: 15px 0; font-size: 20px; font-weight: 300; font-size: 20px; text-decoration: none; }

.breadcrumbs .link-back { display: inline-block; position: relative; padding: 0 0 0 16px; font-size: 20px; font-weight: 300; text-decoration: none; }

/*.breadcrumbs .link-back:before { content: ''; position: absolute; left: 0px; top: 11px; width: 11px; height: 11px; border: 1px solid #000; border-bottom: 0; border-left: 0; -webkit-transform: rotate(-135deg); -ms-transform: rotate(-135deg); transform: rotate(-135deg); }*/

.breadcrumbs--alt { margin: 0; /* padding: 16px 0 15px; */ padding: 16px 0; }

.breadcrumbs .link-back { /* padding: 0 0 0 21px; */ padding: 0; letter-spacing: -.03em; }

/* Tablet Portrait */

@media (min-width: 768px) {
	.breadcrumbs .link-back { font-size: 28px; }
	.breadcrumbs .link-back:before { content: ''; position: absolute; left: 0px; top: 18px; }

	.breadcrumbs--alt { max-width: 920px; margin: 0 auto; }
}