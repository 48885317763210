.job-provider--offset {
  margin-left: 15px;
}

.job-provider a {
  text-decoration: none;
}

@media (min-width: 768px) {

  .job-provider--offset {
    margin-left: 30px;
  }
}
