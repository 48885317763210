.pagination {
  list-style: none;
  text-align: center;
  padding: 0.5rem 0;
  margin-top: 1.5rem;
}

.pagination__page-item {
  display: inline-block;
  padding-left: 0.25em;
  padding-right: 0.25em;
}

.pagination__page-item--disabled a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: inherit;
}