.blog-post {
    text-align: left;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.blog-post__author-and-date {
    margin: 0;
}

.blog-post__body {
    margin-top: 20px;
    margin-bottom: 20px !important;
}
