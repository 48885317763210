#post-a-job .under-label {
  color: #090909;
}

/*
  Based on CSS code by Ruthie BenDor:
  https://gist.github.com/unruthless/413930
 */

#post-a-job sub, sup {
  /* Specified in % so that the sup/sup is the
     right size relative to the surrounding text */
  font-size: 65%;

  /* Zero out the line-height so that it doesn't
     interfere with the positioning that follows */
  line-height: 0;

  /* Where the magic happens: makes all browsers position
     the sup/sup properly, relative to the surrounding text */
  position: relative;

  /* Note that if you're using Eric Meyer's reset.css, this
     is already set and you can remove this rule */
  vertical-align: baseline;
}

#post-a-job sup {
  /* Move the superscripted text up */
  top: -0.5em;
}

#post-a-job sub {
  /* Move the subscripted text down, but only
     half as far down as the superscript moved up */
  bottom: -0.25em;
}

.invalid {
   color: red;
}

.locationInfo {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
}

.locationInfo .form__row--large {
   min-width: 60%;
   margin-right: 30px;
}

@media (max-width: 499px) {
   .locationInfo .form__row--large {
      min-width: 40%;
      max-width: 50%;
      margin-right: 5px;
   }
}
