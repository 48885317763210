.About {
  max-width: 40em;
  margin: auto;
}

.About h3 {
  font-variant: small-caps;
  font-weight: normal;
  margin-top: 4em;
}

.About p {
  text-align: left;
  font-size: 18px;
  margin-top: 2em;
}

.About .section-main__link {
  margin-top: 4em;
}
